$(function () {
	if ($('body').hasClass('detail')) {
		// 画像切り替え
		$('.subimg').on('click', function(){
			var src_chang = "";
			src_chang = $(this).children('img').attr('src');
			$(this).parent().parent().find('.mainimg').children('img').attr({'src': src_chang});
			return false 
		});
    }
});