$(function () {
	if ($('body').hasClass('category-pressrelease')||$('body').hasClass('archive')) {
		//selected付与
		$('.selectwrap.yearlink option').each(function(){
			var address = $(location).attr('href');
			var address02 = address.slice( 0, -1 );
			var val = $(this).val();
			if(address02 == val){
				$(this).prop('selected', true);
			}
			
		})
		
		//記事の表示切り替え
		$('.mainflex .leftwrap .link a').each(function(){
			$(this).click(function(){
				var month = $(this).attr('data-month');
				$('.leftwrap .link').removeClass('current');
				$(this).parent().addClass('current');
				$('.newsblock').each(function(){
					if (month=='all' || $(this).attr('data-month')==month) {
						$(this).addClass('m_active');
					}else{
						$(this).removeClass('m_active');
					}
				});
				return false;
			});
		});
		$('.category_wrap.press .link').each(function(){
			$(this).click(function (){
				var category = $(this).attr('data-category');
				$('.newsblock').each(function(){
					if (category=='all' || $(this).attr('data-category')==category) {
						$(this).addClass('c_active');
					}else{
						$(this).removeClass('c_active');
					}
				});
				return false;
			});
		});
		$('.sort_sp select').change(function(){
			var category = $(this).find('option:selected').val();
			$('.newsblock').each(function(){
				if (category=='all' || $(this).attr('data-category')==category) {
					$(this).addClass('c_active');
				}else{
					$(this).removeClass('c_active');
				}
			});
			return false;
		});
    }
});