$(function () {
    const headerHight = 62;
    //* ページ内リンクの指定
    $("a[href^='#']").not('.noscroll').click(function () {
        const href = $(this).attr('href');
        const target = $(href == '#' || href == '' ? 'html' : href);
        const position = target.offset().top - headerHight;
        $('html, body').animate({
            scrollTop: position
        }, 550, 'swing');
        return false;
    });
    //* ページ外リンクの指定
    const urlHash = location.hash;
    if (urlHash) {
        $('body,html').stop().scrollTop(0);
        setTimeout(function () {
            const target = $(urlHash);
            const position = target.offset().top - headerHight;
            $('body,html').stop().animate({
                scrollTop: position
            }, 500);
        }, 100);
    }

    //* メニュー表示
    $(window).scroll(function () {
        if ($(this).scrollTop() > 200) {
	        // $('.nav').addClass('active');
	        $('.nav .logo').addClass('active');
	        $('.navsp').addClass('active');
	        $('.navhamb').addClass('active');
	        // $('.drawer-nav').addClass('active');
	        // $('.rightnav').css('opacity','0');
        } else {
	        // $('.nav').removeClass('active');
	        $('.nav .logo').removeClass('active');
	        $('.navsp').removeClass('active');
	        $('.navhamb').removeClass('active');
	        // $('.drawer-nav').removeClass('active');
	        // $('.rightnav').css('opacity','1');
        }
    });

    function toggleDrawerNav() {
        if ($(window).width() <= 480) {
            if ($(window).scrollTop() > 200) {
                $('.drawer-nav').addClass('active');
            } else {
                $('.drawer-nav').removeClass('active');
            }
        } else {
            $('.drawer-nav').addClass('active');
        }
    }
    $(window).on('scroll', toggleDrawerNav);

    //* PCメニュー
	$('.menu.open,.menu.close').on('click', function() {
        $('.nav_right').toggleClass('active');
        $('.menu.open').toggleClass('active');
	});
});

