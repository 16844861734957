$(function () {
	if ($('body').hasClass('home')) {
		setTimeout(function(){
			$('.brandtop_area').addClass('active');
		},500);
		setTimeout(function(){
			$('.mainnav').addClass('active');
		},1200);
		setTimeout(function(){
			$('.rightnav').addClass('active');
		},2000);
    }
});