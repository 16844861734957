$(function () {
	if ($('body').hasClass('page-id-8')) {
		var today = new Date();
		today.setDate(today.getDate());
		var yyyy = today.getFullYear();
		var mm = ("0"+(today.getMonth()+1)).slice(-2);
		var dd = ("0"+today.getDate()).slice(-2);
		document.getElementById("today").value = yyyy + '-' + mm + '-' + dd;
		$('input[type="radio"]').on('click', function() {
			if($('input[value="配送"]').is(':checked')){
				$('.dnone_shudan').addClass('active');
				$('.your-ampm01').css('display','none');
				$('.your-ampm02').css('display','block');
			}else{
				$('.dnone_shudan').removeClass('active');
				$('.your-ampm01').css('display','block');
				$('.your-ampm02').css('display','none');
			}
		});
		$('input[type="checkbox"]').on('click', function() {
			if($('input[value="その他"]').is(':checked')){
				$('.dnone_shurui').addClass('active');
			}else{
				$('.dnone_shurui').removeClass('active');
			}
		});
		$('input[type="radio"]').on('click', function() {
			if($('input[value="名入れあり"]').is(':checked')){
				$('.dnone_naire').addClass('active');
			}else{
				$('.dnone_naire').removeClass('active');
			}
		});
		$('input[type="checkbox"]').on('click', function() {
			if($('input[value="紙袋（○○円〜／個）"]').is(':checked')){
				$('.dnone_housou').addClass('active');
			}else if($('input[value="ギフトボックス（○○円〜／個）"]').is(':checked')){
				$('.dnone_housou').addClass('active');
			}else{
				$('.dnone_housou').removeClass('active');
			}
		});
		$('.shomikigenclick').on('click', function() {
			if($('input[value="あり"]', this).is(':checked')){
				$('.dnone_shomikigen').addClass('active');
			}else{
				$('.dnone_shomikigen').removeClass('active');
			}
		});
	}
	if ($('body').hasClass('page-id-10')) {
		var today = new Date();
		today.setDate(today.getDate());
		var mm = ((today.getMonth()+1));
		var dd = (today.getDate());
		$('#visitmonth').val(mm);
		$('#visitday').val(dd);
		console.log(mm);
		console.log(dd);
		$('input[type="radio"]').on('click', function() {
			if($('input[value="名入れあり"]').is(':checked')){
				$('.dnone_naire').addClass('active');
			}else{
				$('.dnone_naire').removeClass('active');
			}
		});
		$('input[type="checkbox"]').on('click', function() {
			if($('input[value="紙袋（○○円〜／個）"]').is(':checked')){
				$('.dnone_housou').addClass('active');
			}else if($('input[value="ギフトボックス（○○円〜／個）"]').is(':checked')){
				$('.dnone_housou').addClass('active');
			}else{
				$('.dnone_housou').removeClass('active');
			}
		});
	}
});